import React from 'react'
import './Testimonios.css'
import testimoniosIcon from '../../../assets/images/home/testimonios-icon.png'
import Carousel from '../../Carousel/Carousel';
import thumbnailTecnolochicas from '../../../assets/images/home/testimonios/thumbnails/tecnolochicas.jpg'
import thumbnailBecalos from '../../../assets/images/home/testimonios/thumbnails/becalos.jpg'
import thumbnailPosible from '../../../assets/images/home/testimonios/thumbnails/retoposible.jpg'
import thumbnailETemprano from '../../../assets/images/home/testimonios/thumbnails/e-temprano.png'
import thumbnailCuantrix from '../../../assets/images/home/testimonios/thumbnails/cuantrix.jpg'
import thumbnailGXMex from '../../../assets/images/home/testimonios/thumbnails/gxmex.jpg'

import thumbnailTecnolochicasMobile from '../../../assets/images/home/testimonios/thumbnails/tecnolochicas-mobile.jpg'
import thumbnailBecalosMobile from '../../../assets/images/home/testimonios/thumbnails/becalos-mobile.jpg'
import thumbnailPosibleMobile from '../../../assets/images/home/testimonios/thumbnails/retoposible-mobile.jpg'
import thumbnailETempranoMobile from '../../../assets/images/home/testimonios/thumbnails/e-temprano-mobile.jpg'
import thumbnailCuantrixMobile from '../../../assets/images/home/testimonios/thumbnails/cuantrix-mobile.jpg'
import thumbnailGXMexMobile from '../../../assets/images/home/testimonios/thumbnails/gxmex-mobile.jpg'
// import { motion } from 'framer-motion';

export function Testimonios({english=false}) {

    const items = [
        {
            type: 'video',
            image: thumbnailTecnolochicas,
            videoUrl: 'https://www.youtube.com/watch?v=t8lg4Hs9nXk&feature=youtu.be'
        },
        {
            type: 'video',
            image: thumbnailBecalos,
            videoUrl: 'https://www.youtube.com/watch?utm_source=informe2023&utm_medium=reginajuarez&utm_id=rise2023&utm_content=carmenmunoz&v=QUtZJJpplvo+&feature=youtu.be'
        },
        {
            type: 'video',
            image: thumbnailPosible,
            videoUrl: 'https://www.youtube.com/watch?v=VaC1fV-5yFY'
        },
        {
            type: 'video',
            image: thumbnailETemprano,
            videoUrl: 'https://www.youtube.com/watch?v=A13akf8d9EY&feature=youtu.be'
        },
        {
            type: 'video',
            image: thumbnailCuantrix,
            videoUrl: 'https://www.youtube.com/watch?v=qtGoyfYOxG4'
        },
        {
            type: 'video',
            image: thumbnailGXMex,
            videoUrl: 'https://www.youtube.com/watch?v=Ad3Qz-kB194&feature=youtu.be'
        }
    ];

    const itemsMobile = [
        {
            type: 'video',
            image: thumbnailTecnolochicasMobile,
            videoUrl: 'https://www.youtube.com/watch?v=t8lg4Hs9nXk&feature=youtu.be'
        },
        {
            type: 'video',
            image: thumbnailBecalosMobile,
            videoUrl: 'https://www.youtube.com/watch?utm_source=informe2023&utm_medium=reginajuarez&utm_id=rise2023&utm_content=carmenmunoz&v=QUtZJJpplvo+&feature=youtu.be'
        },
        {
            type: 'video',
            image: thumbnailPosibleMobile,
            videoUrl: 'https://www.youtube.com/watch?v=VaC1fV-5yFY'
        },
        {
            type: 'video',
            image: thumbnailETempranoMobile,
            videoUrl: 'https://www.youtube.com/watch?v=A13akf8d9EY&feature=youtu.be'
        },
        {
            type: 'video',
            image: thumbnailCuantrixMobile,
            videoUrl: 'https://www.youtube.com/watch?v=qtGoyfYOxG4'
        },
        {
            type: 'video',
            image: thumbnailGXMexMobile,
            videoUrl: 'https://www.youtube.com/watch?v=Ad3Qz-kB194&feature=youtu.be'
        }
    ];

    const textos = {
        en: {
          t1:`READ THEIR TESTIMONIALS`
        },
        es: {
          t1:`CONOCE SUS TESTIMONIOS`
        }
      }

    const txt_v = english ? textos.en : textos.es;

  return (
    <div className='Testimonios'>
        <div className="container-fluid pt-5 pl-5 pb-0 pr-0 m-0">
            <div className='d-flex justify-content-center'>
                <div className='text-center'>
                    <img src={testimoniosIcon} alt='testimonios icon' className='testimonios-icon' />
                    <div className='title pt-2'>
                        {txt_v.t1}
                    </div>
                </div>
            </div>

            {/* <motion.section
                whileInView ={{ translateX: '0vw' }} // Set to the right
                animate={{ translateX: '-50vw' }} // Start to the left
                transition={{ duration: 1 }} // Set animation duration
            > */}
                <div className='mobile-device'>
                    <div className='d-flex justify-content-center mt-5'>
                        <div className='custom-width h-100'>
                            <Carousel
                                items={itemsMobile}
                            />
                        </div>
                    </div>
                </div>

                <div className='web-device'>
                    <div className='d-flex justify-content-center mt-5'>
                        <div className='custom-width h-100'>
                            <Carousel
                                items={items}
                            />
                        </div>
                    </div>
                </div>
                
            {/* </motion.section> */}

            <div className='mt-4 mt-lg-5'>&nbsp;</div>
        </div>
    </div>
  )
}
